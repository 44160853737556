import React from "react";
import LoginButton from "./Login";

export default function LoginPage() {
  return (
    <>
      <div className="flex-container login-container">
        <LoginButton />
      </div>
    </>
  );
}
